/* Achivements.css */
.Achivements-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  align-content: center;
  margin-bottom: 20px;
}

.Achivement-card {
  width: calc(100% / var(--cards-per-row) - 20px); /* Dynamic width based on cardsPerRow */
  margin: 10px;
  padding: 0px 0px 15px 0px;
  background-color: #fff;
  border: 1px solid #add8e6;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.Achivement-card:hover {
  transform: translateY(-5px);
}

.Achivement-card img {
  width: 100%;
  height: 200px;
  border-radius: 5px;
}


.Achivement-card h3{
  display: flex;
  justify-content: center;
  /* align-items: center; */
}
/* Achivementpopup.css */
/* Achivementpopup.css */

.Achivement-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #0e3158;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 80%; /* Set a maximum width */
  max-height: 80%; /* Set a maximum height */
  overflow-y: auto; /* Enable vertical scrolling if content exceeds height */
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

.popupcontent {
  text-align: center;
}

.image-carousel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.image-carousel img {
  max-width: 80%;
  height: auto;
}

.image-carousel button {
  background: #3f99ff;
  border: none;
  border-radius: 12px;
  font-size: 24px;
  cursor: pointer;
  color: #fff;
}

/* Responsive adjustments */
@media only screen and (max-width: 768px) {
  .Achivement-popup {
    max-width: 90%;
  }
}

@media only screen and (max-width: 480px) {
  .Achivement-popup {
    padding: 10px;
    /* top: 40%; */
    /* left: -30%; */
    /* right: 20%; */
    width: 100%;
    /* right: 20%; */
    transform: translate(-50%, -50%);
}
}
