.cards-head h2{
  text-align: center;
  font-size: 1.8em;
}


.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    /* background-color: black; */
    /* padding: 10px; */
  }
  
  .card {
    width: calc(25% - 60px);
    margin: 5px; /* Adjusted margin */
    /* border: 1px solid #ddd; */
    border-radius: 8px;
    overflow: hidden;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    height: 400px;

  }
  
  .card img {
    width: 100%;
    max-width: 250px;
    max-height: 250px;
    border-bottom: 1px solid #ddd;
    border-radius: 250px;
  }
  
  .card-content {
    padding: 10px;
  }

  .card-content h2{
    margin: 0;
    text-align: center;
  }

  .card-content p{
    margin: 0;
    text-align: center;
  }

  .card-content p{
    font-size: 16px;
    font-weight: 600;
    color: rgb(126, 126, 126);
  }
  
  @media only screen and (max-width: 767px) {
    .card {
      width:300px;
    }
  }
  
  @media only screen and (max-width: 480px) {
    .card {
      width: 300px;
      height: 315px;
    }
    .card img {
      width: 81%;
      height: 250px;
      border-bottom: 1px solid #ddd; /* Add a border at the bottom of the image */
    }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    .card {
      width: 300px;
    }
  }