/* Footer.css */

.footer {
    background-color: #0E3158; /* Change the background color to your preference */
    color: #ffffff; /* Change the text color to your preference */
    
  }
  
  .footer-content {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    flex-wrap: wrap; /* Allow content to wrap to the next line if needed */
    padding: 15px 0;
  }
  
  .left-half {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .logo img {
    width: 100%;
    max-width: 200px;
    
  }
  
  .social-icons {
    display: flex;
    gap: 20px;
  }
  
  .social-icon a {
    text-decoration: none;
  }
  
  .social-icon a:hover {
    /* opacity: 0.8; */
    color: #ffd700;
  }
  
  .social-icon a:hover .fa-envelope {
    color: #ffd700; /* Change the color to your preference */
  }
  
  .middle {
    text-align: center;
    /* flex-grow: 1;  */
  }
  
  .source-links a {
    text-decoration: none;
    color: #ffffff; /* Change the color to your preference */
    font-weight: bold;
    font-size: 16px;
    margin-right: 15px;
  }

  .source-links a:hover {
    color: #ffd700;
  }
  
  .right-half {
    display:grid ;
    width: 100%;
    justify-content: center; /* Align items vertically in the middle */
  align-items: center; /* Align items horizontally in the middle */
  /* text-align: center; */
  }

  .right-half p {
    /* text-decoration-line: underline; */
    /* text-decoration-thickness: 5px; */
    font-weight: bold;
    margin-bottom: 0;
  }

  .right-half ul {
    padding: 0;
  }

  .right-half li{
    list-style: none;
  }
  
  .useful-links {
    /* margin-top: 10px;  */
    display: flex;
    flex-direction: column;
  }
  
  .useful-links a {
    text-decoration: none;
    color: #a29e9e; /* Change the color to your preference */
    /* font-weight: bold; */
    font-size: 18px;
    margin-right: 15px;
    transition: color 0.3s ease;
  }
  
  .useful-links a:hover {
    color: #ffd700; /* Change the color on hover to your preference */
    padding: 5px;
    text-decoration: underline;
    text-underline-offset: 5px;
  }

  .copyrights {
    text-align: center;
    padding: 10px;
    background-color: #0E3158;
    color: white;
  }
  
  
  /* Add any additional styles or adjustments as needed */
  /* Mobile */
@media only screen and (min-width: 320px) and (max-width: 819px) {
    .footer-content {
      flex-direction: column;
      text-align: center;
      display: flex;
      justify-content: center;
    }
  
    .left-half, .middle, .right-half {
      width: 100%;
    }
  
  }
  
  /* Tablet */
  @media only screen and (min-width: 820px) and (max-width: 1024px) {
    .footer-content {
      flex-wrap: wrap;
    }
  
    .left-half{
      width: 35%; /* Adjust the width based on your preference */
    }

    .middle{
      width: 30%;
    }

    .right-half {
      width: 35%;
    }
    
  }
  
  /* Desktop */
  @media only screen and (min-width: 1025px) {
    .left-half {
      width: 35%; /* Adjust the width based on your preference */
    }
  
    .middle {
      width: 30%; /* Adjust the width based on your preference */
    }
  
    .right-half {
      width: 30%; 
      margin-left:20px ;
    }
  }
  