/* Form.css */

#connectpage-section{
  background-image: url('../images/1\ \(3\)\ \(1\).png');
  background-size: cover;
  background-position: center;
  background-attachment: fixed; /* Set the background image as fixed */
  color: #fff;
  text-align: center;
  padding: 150px 0; /* Adjust the padding as needed */
  position: relative;
  border-radius: 12px;
}

.connectpage-overlay {
  /* background-color: rgba(0, 0, 0, 0.5); Adjust the transparency as needed */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#connectus{
  padding: 20px;
  font-size: larger;
}

.header-connect-page{
  text-align: center;
  margin: 25px 0;
}
.header-connect-page h2,h3{
  margin: 0;
}

.connect-page {
  display: flex;
  gap: 50px;
  margin: 20px;
}

/* .form-container {
  width: 50%; 
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  color: white;
  background-color: #e6e6e6;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.2);
  border-radius: 1px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group input[type="text"]{
  width:80%;
  border-radius: 0;
  padding: 10px;
  background-color: #000000;
  border: #000000;
  color: #fff;
  border-bottom: 2px solid rgb(255, 255, 255);
  background: transparent;
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #000000;
}

.input-container {
  margin-bottom: 15px;
}

.input-container input,
.input-container select {
  width: 100%;
  padding: 12px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  font-family: 'Poppins', sans-serif;
}

.checkbox-container,
.radio-container,
.text-box-container {
  display: flex;
  gap: 10px;
  align-items: center;
}

.checkbox-container label,
.radio-container label {
  display: flex;
  align-items: center;
  color: #e7e6e6;
}

.checkbox-container input,
.radio-container input {
  margin-right: 5px;
}

.button-container {
  margin-top: 20px;
}

.form-container button {
  position: relative;
  background: transparent;
  padding: 0px 25px;
  color: #ffffff;
  border: 1px solid #fff !important;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
  height: 32px;
  line-height: 0.5em;
  letter-spacing: 0.5px;
}

.form-container button:hover {
  background-color: #4c98af;
} */











.form-container {
  width: 80%; /* Set the desired width */
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  color: #000000; /* Changed text color to black for better readability */
  background-color: #e6e6e6;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); /* Adjusted box shadow */
  border-radius: 5px; /* Increased border radius for rounded corners */
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  font-size: medium;
}

.text-box-container input,
.text-box-container textarea {
  width: 90%;
  padding: 12px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  font-family: 'Poppins', sans-serif;
}

.text-box-container textarea {
  resize: vertical; /* Allow vertical resizing of textarea */
}

.button-container {
  margin-top: 20px;
}

.form-container button {
  background-color: #4B88A2; /* Set button background color */
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  padding: 12px 25px; /* Increased padding for better button size */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.form-container button:hover {
  background-color: #4c98af; /* Darker color on hover */
}


.info-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  font-size: large;
  /* border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); */
}

.address, .email{
  margin-bottom: 10px;
}
.email a{
  text-decoration: none;
  color: #000000;
}

.email a:hover{
  color: #7980f6;
}
.address a{
  text-decoration: none;
  color: #000000;
}

.address a:hover{
  color: #7980f6;
}
/* Responsive styles */
@media only screen and (max-width: 640px) {
  .connect-page {
    flex-direction: column;
    align-items: center;
    margin: 0 ;
  }

  .input-container,
  .checkbox-container,
  .radio-container,
  .text-box-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .info-container{
    font-size: 16px;
    margin: 0;
    padding: 0;
  }

  .form-container {
    width: 100%;
    margin: 0;
  }

 
  #connectpage-section{
    background-image: url('../images/Contactimg.png');
    background-size: cover;
    background-position: center;
    background-attachment: fixed; /* Set the background image as fixed */
    color: #fff;
    text-align: center;
    padding: 200px 0; /* Adjust the padding as needed */
    position: relative;
    border-radius: 12px;
  }
}
/* Form.css */

/* Style the radio buttons */
.radio-container input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px; /* Set the width of the radio button */
  height: 20px; /* Set the height of the radio button */
  border-radius: 50%; /* Make it a circle */
  border: 2px solid #fff; /* Add border */
  background-color: transparent; /* Make background transparent */
  outline: none; /* Remove outline */
  cursor: pointer; /* Add cursor pointer */
  margin-right: 10px; /* Add spacing between radio button and label */
}

/* Style the radio button label */
.radio-container label {
  display: flex;
  align-items: center;
  color: #e7e6e6;
  font-size: 16px;
}

/* Style the radio button when checked */
.radio-container input[type="radio"]:checked {
  background-color: #fff; /* Change background color when checked */
}
/* Form.css */

/* Style the options within the college dropdown */
.form-group select {
  width: 50%;
  border-radius: 5px;
  padding: 12px;
  background-color: #312f2f;
  border: none;
  border-bottom: 2px solid rgb(255, 255, 255);
  background: transparent;
  color: #fff;
  font-size: 16px;
}

.form-group select option {
  background-color: #283c53; /* Background color */
  color: #fff; /* Text color */
  font-size: 16px; /* Font size */
}

/* Optionally, you can style the selected option */
.form-group select option:checked {
  background-color: #4c98af; /* Background color of the selected option */
  color: #fff; /* Text color of the selected option */
}



/* Responsive styles */
@media only screen and (max-width: 768px) {
  /* Tablet styles */
  #connectpage-section {
    background-image: url('../images/Contact\ Tablet.png');
    padding: 150px 0; /* Adjust padding for tablet */
  }
}

@media only screen and (max-width: 480px) {
  /* Mobile styles */
  #connectpage-section {
    background-image: url('../images/Contact\ Mobile\ \(1\).png');
    padding: 100px 0; /* Adjust padding for mobile */
  }

  .form-container {
    width: 80%; /* Adjust width for better mobile display */
  }
}