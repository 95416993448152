/* Default styles for event popup */
.event-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #0e3158ab;
  border-radius: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  padding: 40px;
  max-width: 500px;
  width: 90%;
  backdrop-filter: blur(7px);
}

/* Close icon */
.close-icon {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
  font-size: 24px;
  color: #ffffff; /* Blue color for the close icon */
}

/* Heading */
.popupcontent h2 {
  font-size: 32px; /* Larger font size for the heading */
  margin-bottom: 30px; /* Increased margin bottom for spacing */
  color: #ffffff; /* Blue color for the heading */
  text-decoration: underline;
}

/* Description */
.popupcontent p {
  font-size: 20px; /* Larger font size for the description */
  line-height: 1.6;
  color: #ffffff; /* Darker color for better readability */
  margin-bottom: 40px; /* Increased margin bottom for spacing */
}

/* Gallery link styled as a button */
.popupcontent a.gallery-button {
  display: inline-block;
  padding: 15px 30px;
  background-color: #0e3158; /* Blue background color */
  color: #ffffff; /* White text color */
  text-decoration: none;
  border-radius: 10px;
  border: none;
  font-size: 20px; /* Larger font size for the button text */
  transition: background-color 0.3s ease;
}

.popupcontent a.gallery-button:hover {
  background-color: #0e3158; /* Darker blue color on hover */
}

.popupcontent {
  text-align: center;
  max-width: 100%;
  max-height: 100%;
}

.popupcontent a {
  background-color: #0e3158;
  color: #ffffff;
  text-decoration: none;
  padding: 7px 14px 7px 14px;
  border-radius: 30px;
  border: solid;
}

/* Responsive styles for tablets (min-width: 768px) */
@media only screen and (min-width: 768px) {
  .event-popup {
      max-width: 70%;
  }
}

/* Responsive styles for desktops (min-width: 1024px) */
@media only screen and (min-width: 1024px) {
  .event-popup {
      max-width: 50%;
  }
}

/* Responsive styles for large desktops (min-width: 1440px) */
@media only screen and (min-width: 1440px) {
  .event-popup {
      max-width: 40%;
  }
}