.team-table {
  font-family: Arial, sans-serif;
  padding-top: 80px;
}

.team-table h2 {
  text-align: center;
  margin: 0;
  color: #649dfa;
  padding-top: 20px; /* Adjusted padding */
  padding-bottom: 20px; /* Added padding */
}

.custom-table-container {
  align-items: center;
  overflow-x: auto;
  margin: 0px 10px;
}

.custom-table {
  width: 90%;
  margin: auto;
  border-collapse: collapse;
}

.custom-table th,
.custom-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: center;
}

.custom-table th {
  background-color: #f2f2f2;
  color: #333;
  font-size: 16px;
}

.custom-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.subteam-list {
  list-style-type: none;
  padding: 0;
}

.subteam {
  margin: 10px;
}

.subteam a:hover {
  text-decoration: underline;
}

td a{
  text-decoration: none;
  color: black;
}

table{
  font-family: sans-serif;
  font-size: 14px;
}

