/* Mentor.css */

/* Mentor container */

h2{
  margin: 0;
}
.mentor-container {
  font-family: Arial, sans-serif;
  padding-top: 80px;
  align-items: center;
}

/* Table styles */

.mentor-table-container {
  align-items: center;
  overflow-x: auto;
  margin: 0px 10px;
}

.mentor-table {
  margin: auto;
  margin-top: 20px;
  width: 90%;
  border-collapse: collapse;
}

.mentor-table th,
.mentor-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: center;
}

.mentor-table th {
  background-color: #f2f2f2;
  color: #333;
}

.mentor-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.mentor-table tbody tr:hover {
  background-color: #f2f2f2;
}

/* Title styles */
.mentor-title {
  font-size: 24px;
  padding-top: 20px; /* Adjusted padding */
  padding-bottom: 20px; /* Added padding */
  text-align: center;
  color: #649dfa;
}

/* Media queries for responsive design */
@media (max-width: 768px) {
  .mentor-container {
    /* background-image: none; */
    padding-top: 100px;
    text-align: center;
  }
}
