/* Events.css */

.events-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  align-content: center;
}
/* Events.css */

.close-btn {
  padding: 12px;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  font-size: 30px;
  color: #fff; /* White color */
}

.event-card {
  width: calc(100% / var(--cards-per-row) - 20px); /* Dynamic width based on cardsPerRow */
  margin: 10px;
  padding: 0px 0px 15px 0px;
  background-color: #fff;
  border: 1px solid #add8e6;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  position: relative; /* Required for badge positioning */
}


.event-card h3{
  display: flex;
  justify-content: center;
  /* align-items: center; */
}
.nav-icons button{
  color: white;                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         
  background-color: #333;
  padding: 10px 4px;
  font-size: larger;
  border-radius: 2px;
}

.event-card:hover {
  transform: scale(1.1);
  background-color: #e0e8f0;
  box-shadow: 0 3px 5px rgba(0, 0, 255, 0.2);
  margin-bottom: 12px;
}

.event-card img {
  width: 100%;

  border-radius: 5px;
}