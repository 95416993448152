/* Folder Card Container */
.gallery-container{
  padding-top:100px;
  align-items: center;
}

.gallery-container h2{
  text-align: center;
}

.gallery{
  padding: 0px 30px;
}

.gallery h1 {
  border-left: 3px solid #3498db; /* Change the color as needed */
  padding-left: 10px; /* Adjust padding as needed */
  margin-bottom: 10px; /* Adjust margin as needed */
  width: fit-content;
}

.gallery h1:hover {
  /* background-color: #3498db; */
  color: rgb(255, 255, 255);
  width: 315px;
  box-shadow: inset 115px 0 0 0 #3498db;
}

.gallery h1 {
  font-size: 1.8em; /* Adjust font size as needed */
  margin-bottom: 0;
  box-shadow: inset 0 0 0 0 #3498db;
  -webkit-transition: all ease 1.8s;
  -moz-transition: all ease 1.8s;
  transition: all ease 1s;
}

.folder-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  padding: 6em 2em 4em 2em; /* Adjust padding as needed */
}

/* Folder Card */
.folder-card {
  background-color: #ffffff; /* Blue color */
  overflow: hidden;
  position: relative;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Enhanced box shadow */
  transition: transform 0.5s ease, box-shadow 0.5s ease; /* Add transition for smooth animation */
  margin: auto;
  height: 300px;
  width: 23%;
}

/* Folder Name */
.folderNamestyle {
  height: 10%;
  margin: 0;
  padding: 1em 1em 0em 1em; /* Add padding for better spacing */
  font-size: 1.2em; /* Adjust font size */
  color: #0E3158 !important; /* White color */
  text-align: center;
  width: auto;
  overflow: hidden;
}
.folderNamestyle:hover {
  margin: 0;
  padding: 1em 1em 0em 1em; /* Add padding for better spacing */
  font-size: 1.2em; /* Adjust font size */
  color: #0E3158 !important; /* White color */
  text-align: center;
}

/* Image Preview */
.image-preview {
  overflow: hidden;
  padding: 12px;
  width: auto;
  height: 200px;
}

.image-preview img {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  transition: transform 0.5s ease; 
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .folder-card {
    width: 30%; /* Adjust width for smaller screens */
  }
}

@media (max-width: 768px) {
  .folder-card {
    width: 45%; /* Adjust width for even smaller screens */
  }
}

@media (max-width: 576px) {
  .folder-card {
    width: 100%; /* Adjust width for mobile screens */
  }
}