/* Folder Page Container */
.folder-page-container {
  position: relative;
  height: 100vh;
  max-width: 1200px;
  margin: auto;
}

/* Left Div */
.left-div {
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  padding: 100px 2% 2% 2%;
  gap: 10px;
  justify-content: space-around;
}
.leftright{
  position: relative;
  display: flex;

}
.left-div img {
  width: 250px;
  height: 250px;
  object-fit: cover;
  /* border-radius: 8px; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.left-div img:hover {
  transform: scale(1.05);
}

.left-div-clicked {
  width: 50%;
  transition: width 0.5s;
}

/* Right Div */
.right-div {
  position: relative;
  flex: 1;
  width: 0%;
  transition: width 0.5s;
  overflow: hidden;
}

.right-div-clicked {
  width: 50%;
  transition: width 0.5s;
}

/* Popup */
.popup {
  position: fixed;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  max-width: 80%;
  max-height: 80%;
  text-align: center;
}

.popup-content img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  border-radius: 12px;
  box-shadow: 12px;
}

.popup-content p {
  color: #fff;
  margin-top: 10px;
}

/* Previous and Next Icons */
.prev, .next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  color: #fff;
  cursor: pointer;
  z-index: 999;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

/* Additional Customization */
.popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}
/* Media Query for Mobile Screens */
@media screen and (max-width: 768px) {
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }

  .popup-mobile .popup-content {
    max-width: 80%;
    max-height: 80%;
    text-align: center;
  }
  /* Right Div */
.right-div {
  position: relative;
  flex: 1;
  width: 0%;
  transition: width 0.5s;
  overflow: hidden;
}

.right-div-clicked {
  width: 50%;
  transition: width 0.5s;
}
.left-div-clicked {
  width: 100%;
  transition: width 0.5s;
}
}