/* Navbar.css */

nav {
  font-family: "Montserrat", Sans-serif;
  font-size: small;
  font-weight: 300;
  text-transform: uppercase;
  width: 100%;
  position: fixed;
  background-color: #0e3158;
  color: white;
  z-index: 10;
}

nav div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  max-width: 1200px;
  margin: auto;
}

nav h1 {
  margin: 0;
  color: white;
  cursor: pointer;
  padding: 10px;
}

.nav-items {
  list-style: none;
  display: flex;
  align-items: center;
}

.nav-items li {
  margin-right: 20px;
}

.nav-items a {
  text-decoration: none;
  color: #b6b6b6;
  font-weight: bold;
  font-size: 15px;
  position: relative;
}

.nav-items a:hover {
  color: #ff6600; /* Change to your desired hover color */
}

a:-webkit-any-link {
  cursor: pointer;
  text-decoration: none;
}

.search-bar {
  display: flex;
}

.search-bar {
  position: relative;
  display: inline-block;
}

.search-input {
  padding: 10px 30px 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 200px;
  box-sizing: border-box;
}

.search-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 20px; /* Adjust width as needed */
  height: 20px; /* Adjust height as needed */
  background-image: url("../images/bgimg_si.png"); /* Provide the correct path or URL */
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}
.submitbtn {
  padding: 8px;
  border: none;
  border-radius: 0 5px 5px 0;
}

.menu-button {
  display: none; /* Initially hide on larger screens */
  cursor: pointer;
}

/* Navbar.css */

/* Existing styles... */

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #0e3158;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 0px;
}

.dropdown-content a {
  color: white;
  padding: 12px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

/* Existing media queries... */

@media only screen and (max-width: 600px) {
  nav h1 {
    font-size: 15px;
  }
}
@media (max-width: 1024px) {
  .menu-button {
    display: block;
    margin: 0;
  }

  /* Hide navigation items on tablets and smaller screens */
  .nav-items {
    display: none;
  }
}

/* sidebar css */
/* Sidebar.css */

.sidebar {
  height: 100%;
  width: 250px;
  position: fixed;
  top: 0;
  padding-top: 30px;
  background-color: #283c53;
  color: white;
  transition: 0.3s;
  opacity: 5;
  visibility: visible;
  /* backdrop-filter: blur(10px); */
  z-index: 100;
}

.close-icon {
  position: absolute;
  top: 20px;
  right: 30px;
  cursor: pointer;
  font-size: 24px; /* Adjust the font size to make the icon bigger */
  color: #000;
}

.closed {
  width: 0;
}

.sidebar h2 {
  margin-bottom: 20px;
}

.sidebar a {
  padding: 8px;
  text-decoration: none;
  font-size: 16px;
  color: #cecece;
  display: block;
  transition: 0.3s;
}

.sidebar a:hover,
.sidebar a:focus {
  color: #f1f1f1;
}

.sidebar-elements {
  position: relative;
  margin-top: 30px;
}

/* Responsive Styles */

/* Small screens */
@media only screen and (max-width: 600px) {
  .sidebar {
    width: 85%;
  }

  .closed {
    width: 0;
  }

  .sidebar button {
    right: 20px;
  }
  .sidebar-elements {
    text-align: center;
  }
}

/* Medium screens */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .sidebar {
    width: 40%;
  }

  .closed {
    width: 0;
  }
  .sidebar-elements {
    text-align: center;
  }
}

/* Large screens */
@media only screen and (min-width: 1025px) {
  .sidebar {
    width: 350px;
  }

  .closed {
    width: 0;
  }
}
